import React, { useState } from 'react';
import { sendMessage } from '../api/contactApi.js';
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';
import { Link } from "react-router-dom";
import img1 from '../assets/images/icon/icon1.svg';
import img2 from '../assets/images/icon/icon2.svg';
import img3 from '../assets/images/icon/icon3.svg';

//showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function IntroVideo() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("profile-page");
        return function cleanup() {
            document.body.classList.remove("profile-page");
        };
    });


    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <ToastContainer /> {/* Add the ToastContainer component for showing messages */}
            <PageTitle title='Intro Video' />
            <section className="tf-section tf-map" style={{ display: "flex", justifyContent: "center" }}>
                <h2>Shotagent Introduction Video</h2>
            </section>
            <div className="container"
                style={{
                    display: "flex",
                    justifyContent: "center",

                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <video controls style={{ height: "75%" }} preload="metadata">
                        <source src="https://api.shotagent.com/intro-video" type="video/mp4" />
                        {/*<source src="http://localhost:5005/intro-video" type="video/mp4" />*/}
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
            {<Footer data={dataFooter} />}
            {<Bottom classection='widget-social-footer' />}
        </div>
    );
}

export default IntroVideo;
