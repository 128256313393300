
import Home1 from "./Home1";
import Explore from "./Explore";
import About from "./About";
import Author from "./Author";
//import Profile from "./Profile";
//import EditProfile from "./EditProfile";
import EditProfileClient from "./EditProfile-Client";
import EditProfileFreelancer from "./EditProfile-Freelancer";
import ClientLogin from "./ClientLogin";
import ClientRegister from "./ClientRegister";
import FreelancerLogin from "./FreelancerLogin";
import FreelancerRegister from "./FreelancerRegister";
import Contact from "./Contact";
import ClientDashboard from "./ClienDashboard";
import Applicants from "./Applicants";
import ProjectProposal from "./ProjectProposal";
import ClientProjectManagement from "./ClientProjectManagement";
import ClientProjectArchive from "./ClientProjectArchive";
import FreelancerDashboard from "./FreelancerDashboard";
import ProjectDetail from "./ProjectDetail";
import BuyCoinClient from "./BuyCoinClient";
import BuyCoinFreelancer from "./BuyCoinFreelancer";
import FreelancerProjectManagement from "./FreelancerProjectManagement";
import FreelancerProjectArchive from "./FreelancerProjectArchive";
import ProjectDetailClient from "./ProjectDetailClient";
import EditProject from "./EditProject";
import IntroVideo from "./IntroVideo";

const routes = [
    { path: '/', component: <Home1 />},
    { path: '/explore', component: <Explore />},
    { path: '/about', component: <About />},
    { path: '/author', component: <Author />},
    //{ path: '/profile', component: <Profile />},
   // { path: '/editProfile', component: <EditProfile />},
    { path: '/editProfile-client', component: <EditProfileClient />},
    { path: '/editProfile-freelancer', component: <EditProfileFreelancer />},
    { path: '/login-client', component: <ClientLogin />},
    { path: '/register-client', component: <ClientRegister />},
    { path: '/login-freelancer', component: <FreelancerLogin />},
    { path: '/register-freelancer', component: <FreelancerRegister />},
    { path: '/contact', component: <Contact />},
    { path: '/dashboard-client', component: <ClientDashboard />},
    { path: '/project-applicants', component: <Applicants />},
    { path: '/project-proposal', component: <ProjectProposal />},
    { path: '/client-project-management', component: <ClientProjectManagement />},
    { path: '/client-project-archive', component: <ClientProjectArchive />},
    { path: '/dashboard-freelancer', component: <FreelancerDashboard />},
    { path: '/project-detail', component: <ProjectDetail />},
    { path: '/buy-coin-client', component: <BuyCoinClient />},
    { path: '/buy-coin-freelancer', component: <BuyCoinFreelancer />},
    { path: '/freelancer-project-management', component: <FreelancerProjectManagement />},
    { path: '/freelancer-project-archive', component: <FreelancerProjectArchive />},
    { path: '/client-project-detail', component: <ProjectDetailClient />},
    { path: '/client-edit-request', component: <EditProject />},
    { path: '/intro-video', component: <IntroVideo />},




  ]
  
  export default routes;