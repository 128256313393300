import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import menus from '../../pages/menu';
import DarkMode from './DarkMode';
import Button from '../button/button-st1';
import './styles.scss';
import logo from '../../assets/images/logo/logo_dark.png';

const Header = () => {
    const [scroll, setScroll] = useState(false);
    const [menuState, setMenuState] = useState({ active: null, dropdownIndex: null });
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            setScroll(window.scrollY > 200);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleMenuActive = () => {
        setMenuState(prevState => ({ ...prevState, active: !prevState.active }));
    };

    const handleDropdown = index => {
        setMenuState(prevState => ({ ...prevState, dropdownIndex: index }));
    };

    const isEmailAvailable = localStorage.getItem("email");

    const handleLogout = () => {
        localStorage.removeItem("email");
        window.location.href = "https://shotagent.com/"; // Redirect to an external URL
    };

    return (
        <header id="header_main" className={`header_1 js-header ${scroll ? 'is-fixed' : ''}`}>
            <div className="themes-container">
                        <div id="site-header-inner">
                            <div className="wrap-box flex">
                                <div id="site-logo" className="clearfix">
                                    <div id="site-logo-inner">
                                        <NavLink to="/" className="main-logo"><img src={logo} alt="Icoland" /></NavLink>
                                    </div>
                                </div>
                                <div className={`mobile-button ${menuState.active ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
                                <nav id="main-nav" className={`main-nav ${menuState.active ? 'active' : ''}`} >
                                    <ul id="menu-primary-menu" className="menu">
                                        {menus.map((data, idx) => (
                                            <li key={idx} onClick={() => handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${menuState.dropdownIndex === idx ? 'active' : ''}`}>
                                                <Link to={data.links}>{data.name}</Link>
                                                {data.namesub && (
                                                    <ul className="sub-menu">
                                                        {data.namesub.map((submenu) => (
                                                            <li key={submenu.id} className="menu-item"><NavLink to={submenu.links} onClick={handleDropdown}>{submenu.sub}</NavLink></li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                                <DarkMode />
                                <div className="flat-search-btn flex">
                                    <div className="sc-btn-top" id="site-header">
                                        <Button title={isEmailAvailable ? 'Client Dashboard' : 'Video Client'} addclass='header-slider style wallet fl-button pri-1' path={isEmailAvailable ? '/dashboard-client' : '/login-client'} />
                                    </div>
                                    <div style={{ marginLeft: "1em", marginRight: "1em" }}>
                                        <h3>OR</h3>
                                    </div>
                                    <div className="sc-btn-top" id="site-header">
                                        <Button title={isEmailAvailable ? 'Freelancer Dashboard' : 'Video Freelancer'} addclass='header-slider style wallet fl-button pri-1' path={isEmailAvailable ? '/dashboard-freelancer' : '/login-freelancer'} />
                                    </div>
                                    {isEmailAvailable && (
                                        <div className="sc-btn-top" id="site-header" style={{marginLeft:"2em"}}>
                                            <Button title="Log Out" addclass="btn-4" onClick={handleLogout} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
            </div>
        </header>
    );
};

export default Header;
