const menus = [

    {
        id: 1,
        name: 'About Us',
        links: '/about',
    },
    {
        id: 2,
        name: 'Contact Us',
        links: '/contact',
    },
    {
        id: 3,
        name: 'Intro Video',
        links: '/intro-video',
    },
    
]

export default menus;